import React, { useState } from 'react';
import { storage, database } from './firebase';
import "./App.css"
import QRCode from 'qrcode.react';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  // State variables to track video upload progress and URLs
  const [video, setVideo] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoUrl, setVideoUrl] = useState('');
  const [qrData, setQrData] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Function to handle video selection
  const handleVideoChange = (e) => {
    const selectedVideo = e.target.files[0];

    if (selectedVideo) {
      // Check if video duration is less than or equal to 20 seconds
      const videoObject = document.createElement('video');
      videoObject.src = URL.createObjectURL(selectedVideo);

      videoObject.onloadedmetadata = () => {
        if (videoObject.duration <= 30) {
          setVideo(selectedVideo);
          setErrorMessage(''); // Clear any previous error message
        } else {
          setVideo(null); // Reset selected video
          setErrorMessage('Video duration should be 30 seconds or less.');
        }
      };
    }
  };

  // Function to handle video upload
  const handleUpload = () => {
    // Create a reference to the storage location for the video
    const uploadTask = storage.ref(`videos/${video.name}`).put(video);

    // Track upload progress
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress(progress);
      },
      (error) => {
        console.error(error.message);
      },
      () => {
        // Get video URL after successful upload and save it to Firebase database
        storage
          .ref('videos')
          .child(video.name)
          .getDownloadURL()
          .then((url) => {
            setVideoUrl(url);
            database.ref('videos').push({
              url,
              name: video.name,
            });
          });
      }
    );
  };
 // Function to handle QR code download
 const handleDownloadQR = () => {
  const canvas = document.querySelector('canvas'); // Assuming the QR code is rendered on a canvas
  const url = canvas.toDataURL('image/png');
  const link = document.createElement('a');
  link.href = url;
  link.download = 'qrcode.png';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  // Function to generate QR code from the video URL
  const handleGenerateQR = () => {
    if (videoUrl) {
      setQrData(videoUrl);
    }
  };

  return (
    <div className="container mt-5">
    <header className="text-center mb-4">
     <a href='https://personallyloved.com/'> <img src="./logo2.png" alt="Logo" className="logo" /></a>
      <h1>Video Upload and QR Code Generator</h1>
    </header>

    <div className="row">
      
      {/* Right Column: Video Upload Button and Input */}
      <div className="col-md-6 col-sm-12">
        <div className="mb-3">
          <input
            type="file"
            className="form-control"
            accept="video/*"
            onChange={handleVideoChange}
          />
        </div>
        <button className="btn btn-primary mb-3"
          disabled={!video || video.duration > 30} // Disable if no video or duration > 30 seconds
         onClick={handleUpload}>
          Upload Video
        </button>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
     

      {/* Step 3: Display Upload Progress */}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <div>
          <h2>Uploading...</h2>
          <progress className="progress" value={uploadProgress} max="100" />
        </div>
      )}

      {/* Step 4: Display Video URL and Generate QR Code Button */}
      {videoUrl && uploadProgress === 100 && (
        <div>
          {/* Step 5: Generate QR Code */}
          <button className="btn btn-success" onClick={handleGenerateQR}>
            Generate QR Code
          </button>
        </div>
      )}

      {/* Step 6: Display QR Code */}
      {qrData && (
        <div>
        <div className='row'>
          <div className='col-md-8'>
          <h2 className="mt-3">QR Code:</h2>
          <button className="btn btn-info mt-3" onClick={handleDownloadQR}>
              Download QR Code
            </button>
            <br></br>
            <br></br>
          </div>
          <div className='col-md-4'>
          <div className='QR-picQR-pic'>
          <QRCode value={qrData} size={800}
           />
           </div>
          </div>
        </div>
         
        </div>
      )}
      </div>
      {/* Left Column: Steps List */}
      <div className="col-md-6 col-sm-12">
        <h4 className="">
          Easy Steps to Upload Video and Generate QR:
        </h4>
        <ul className="list-unstyled">
          <li>Select a video file.</li>
          <li>Click on "Upload Video".</li>
          <li>Wait for the upload to complete.</li>
          <li>QR Code will be shown for sharing.</li>
          <li>Click & Download the QR Image.</li>
          <li style={{textDecoration:"underline"}}><span style={{color:"red"}}>Note: </span>
          we do not store videos.</li>
        </ul>
      </div>

    </div>
    </div>
  );
};

export default App;
